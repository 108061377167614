import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import './LoginPage.css'
import { useLoginUserMutation  } from '../../APIs/userAPI'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../logo.svg';
import { storeToken } from '../../actions/user/user.actions.default';
import { Spinner } from 'react-bootstrap';

const LoginPage = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(true);
  
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [loginUser, { isLoading, isError }] = useLoginUserMutation ()

  const handleSubmit = async(e) => {

    e.preventDefault();

    try {
      // Dispatch the login mutation with user credentials
      const result = await loginUser({ email, password });

      // Check for potential HTTP status code 401
      if (result.error) {
        // Handle 401 Unauthorized with custom logic
        console.log(result);
        showError(result.error.data.message);
      } else {
          // No error, proceed with the successful login
          storeToken(result.data.user.token);
          sessionStorage.removeItem("freemium");
          navigate("/home");
      }
    } catch (err) {
      // Handle login error
      showError(err.message);
    }
  };

  const showError = (message) => {
    
    setErrorMessage(message)

  }

  return (
    <div>
      <div className="logo-section"><img src={logo} className="App-logo" alt="logo" /></div>
      <div className="main-section-login">
        <h2>Login</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>E-Mailadresse</Form.Label>
            <Form.Control type="email" value={email} onChange={handleEmailChange} placeholder="Bitte gib deine E-Mailadresse ein"/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Passwort</Form.Label>
            <Form.Control type="password" value={password} onChange={handlePasswordChange} placeholder='Bitte gib dein Passwort ein' />
          </Form.Group>
          <Button className="buttom-full-width" variant="primary" type="submit">
            { isLoading ? 
              <Spinner animation='border' role='status' size='sm'>
                <span className='visually-hidden'>Laden...</span>
              </Spinner> : 
              "Login"
            }
          </Button>
          <p style={{marginTop: "20px"}}>Du hast noch kein Account? Dann <Link to="/register"> registriere dich jetzt.</Link></p>
          {isError && (
            <Alert className="custom-margin" variant="danger" dismissible>
              <Alert.Heading>Es ist ein Fehler aufgetreten!</Alert.Heading>
              <p>
                {errorMessage}
              </p>
            </Alert>
          )}
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;