// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getToken from "../actions/user/user.actions"

// Define a service using a base URL and expected endpoints
const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_BASE_URL + '/documents' }),
  endpoints: (builder) => ({
    addDocument: builder.mutation({
      query: (formData) => ({
        url: '',
        method: 'POST',
        headers: {
            "Authorization": "Bearer " + getToken()
        },
        body: formData,
      })
    }),
    getDocuments: builder.mutation({
      query: (formData) => ({
        url: '',
        method: 'GET',
        headers: {
            "Authorization": "Bearer " + getToken()
        },
        body: formData,
      })
    }),
    deleteDocument: builder.mutation({
      query: (docId) => ({
        url: '/' + docId,
        method: 'DELETE',
        headers: {
            "Authorization": "Bearer " + getToken()
        }
      })
    })
  }),
})

export const { useAddDocumentMutation, useGetDocumentsMutation, useDeleteDocumentMutation } = documentApi;

export default documentApi;