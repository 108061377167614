// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_BASE_URL + '/users' }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      })
    }),
    registerUser: builder.mutation({
      query: (userValues) => ({
        url: "/register",
        method: "POST",
        body: userValues
      })
    }),
    registerFreemiumUser: builder.mutation({
      query: () => ({
        url: "/register/freemium",
        method: "POST"
      })
    })
  }),
})

export const { useLoginUserMutation, useRegisterUserMutation, useRegisterFreemiumUserMutation } = userApi;
export default userApi;