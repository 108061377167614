import './App.css';
import { Outlet, useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import { IoChatbubblesSharp } from "react-icons/io5";
// import { IoDocumentText } from "react-icons/io5";
//import { IoLogOut } from "react-icons/io5";
import { storeToken } from './actions/user/user.actions.default';
import { useRegisterFreemiumUserMutation  } from './APIs/userAPI'

function App() {
  const loggedIn = useLoaderData();
  const navigate = useNavigate();
  const [registerFreemiumUser, { isError }] = useRegisterFreemiumUserMutation ();
  const location = useLocation();

  useEffect(() => {
    if(!loggedIn) {
        // Dispatch the registration mutation for a freemium user
        const registerFreemium = async () => {
          try {
            const result = await registerFreemiumUser();
      
            // Check for potential HTTP status code 401
            if (isError) {
              // Handle 401 Unauthorized with custom logic
              console.log(result);
            } else {
                // No error, proceed with the successful login
                console.log(result)
                storeToken(result.data.user.token)
                sessionStorage.setItem("freemium", true);
                navigate("/home");
            }
          } catch (err) {
            // Handle login error
            console.log(err.message)
          }
        };

        registerFreemium();

    } else {
      navigate("/home" + location.search);
    }
  },[loggedIn, registerFreemiumUser, isError, navigate, location.search])
  

  return (
    <div className="App">
      <header className="App-header">
          Paperdex
      </header>
      <Outlet />
      {
        /* 
      <div style={{ visibility: 'hidden' }}>
            <Navbar bg="dark" data-bs-theme="dark" className="nav-bar justify-content-between">
            <Container>
              <Nav id="navbar" className="justify-content-between">
                <Nav.Link active={window.location.pathname === '/home'} onClick={() => {
                  navigate("/home");
                }}><IoChatbubblesSharp /></Nav.Link>
                <Nav.Link active={window.location.pathname === '/documents/add'} onClick={() => {
                  navigate("/documents/add");
                }}><IoDocumentText /></Nav.Link>
                <Nav.Link onClick={() => {
                  removeToken();
                  navigate("/login");
                }} to="/login"><IoLogOut /></Nav.Link>
              </Nav>
              </Container>
          </Navbar>
      </div>
      */
      }
    </div>
  );
}

export default App;
