// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getToken from "../actions/user/user.actions"

// Define a service using a base URL and expected endpoints
const messageApi = createApi({
  reducerPath: 'messageApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_BASE_URL + '/messages' }),
  endpoints: (builder) => ({
    addMessage: builder.mutation({
      query: (userInput) => ({
        url: '',
        method: 'POST',
        headers: {
            "Authorization": "Bearer " + getToken()
        },
        body: {
          text: userInput
        },
      })
    }),
    addMessageAnswer: builder.mutation({
      query: (body) => ({
        url: "/" + body.messageId + "/answer",
        method: "PUT",
        headers: {
          "Authorization": "Bearer " + getToken()
        },
        body: body
      })
    })
  }),
})

export const { useAddMessageMutation, useAddMessageAnswerMutation } = messageApi;

export default messageApi;