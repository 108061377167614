import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import './DocumentUpload.css'
import { useNavigate } from 'react-router-dom';
import { useAddDocumentMutation, useDeleteDocumentMutation, useGetDocumentsMutation } from '../../APIs/documentAPI';
import { Spinner } from 'react-bootstrap';
import { IoTrashBin, IoDocumentTextOutline } from "react-icons/io5";


const DocumentUpload = () => {

    const [docFile, setDocFile] = useState(null);
    const [docReload, setDocReload] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    
    const navigate = useNavigate();

    const [getUserDocuments /*, { getUserDocumentsIsError, getUserDocumentsError } */] = useGetDocumentsMutation()
    const [deleteDocument, { isLoading: isLoadingDdeletion }] = useDeleteDocumentMutation();
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
      const fetchUserDocument = async () => {
        try {
            const result = await getUserDocuments();  
            console.log(result);
            setDocuments(result.data)
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
      };
      fetchUserDocument();
    }, [getUserDocuments, docReload]);

    const handleDocumentOpenClick = (document) => {

        var byteCharacters = atob(document.document_string);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        
    };

    const handleDocumentDeleteClick = async(document) => {
        const result = await deleteDocument(document.id)

        if (result.error) {
            console.log(result.error);
            showError(result.error.data.message);
        } else {
            setDocReload(!docReload);
        }
    };
    
    const handleDocChange = (e) => {
        setDocFile(e.target.files[0]);
    };

    const [addDocument, { isLoading, isError, error }] = useAddDocumentMutation();

    const handleSubmit = async(e) => {

        e.preventDefault();

        try {
            let formData = new FormData();
            formData.append(
                "uploadedFile",
                docFile,
                docFile.name
            );

            const result = await addDocument(formData);

            // Check for error
            if (result.error) {
                showError(result.error.data.message);
                console.log(error);
            } else {
                // No error, proceed with the successful file upload
                navigate("/home");
            }

        } catch (err) {
            // Handle login error
            showError(err.message)
        }
    };

    const showError = (message) => {
        setErrorMessage(message)
    }

    return (
        <div>
        <div className="header-section" style={{ height: "20%", paddingTop: "30px" }}>
            <h1>Dokument hochladen</h1>
        </div>
        <div className="main-section" style={{ height: "60%" }}>
            {documents && (
            <div>   
                <h2>Deine Dokumente</h2>
                {documents.map((document) => (
                    <Card style={{ width: '18rem'}} className="pdf-card" key={document.id}>
                        <IoDocumentTextOutline id='document-icon' />
                        <Card.Body>
                            <Card.Title>{document.filename}</Card.Title>
                            <Card.Text>
                            This is your document description
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleDocumentOpenClick(document)}>Open document</Button>
                            <Button className='delete-button' variant="danger" onClick={() => handleDocumentDeleteClick(document)}>
                                {
                                    isLoadingDdeletion ? 
                                    <Spinner animation='border' role='status' size='sm'>
                                        <span className='visually-hidden'>Laden...</span>
                                    </Spinner> : 
                                    <IoTrashBin className='delete-icon' />
                                }
                            </Button>
                        </Card.Body>
                    </Card>
                 ))}
            </div>)}
            
            <h2>Dokument hochladen</h2>
            <Form onSubmit={handleSubmit} className='document-form'>
                <Form.Group className="mb-3 document-form-input" controlId="formBasicEmail">
                    <Form.Label>Wähle bitte ein Dokument (PDF) mit deinen Versicherungsinformationen (Versicherungsbedingungen, Police) aus. Die Dokumente werden analysiert und für die Beantwortung deiner Fragen genutzt.</Form.Label>
                    <Form.Control type="file" onChange={handleDocChange} placeholder="Wähle ein Dokument aus" />
                </Form.Group>
                <Button className="buttom-full-width" variant="primary" type="submit">
                    { isLoading ? 
                        <Spinner animation='border' role='status' size='sm'>
                            <span className='visually-hidden'>Laden...</span>
                        </Spinner> : 
                        "Weiter"
                    }
                </Button>
                {isError && (
                    <Alert className="custom-margin" variant="danger" dismissible>
                    <Alert.Heading>Es ist ein Fehler aufgetreten!</Alert.Heading>
                    <p>
                        {errorMessage}
                    </p>
                    </Alert>
                )}
            </Form>
        </div>
        </div>
    );
}

export default DocumentUpload;