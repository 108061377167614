import Cookies from 'js-cookie';

export async function userLoader() {

    if(getToken()){
        return true;
    } else {
        return false;
    }
}

function getToken() {
    return Cookies.get('access_token');
}

export default getToken;