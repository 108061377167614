import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './HomePage.css'
import { useAddMessageMutation, useAddMessageAnswerMutation } from '../../APIs/messageAPI';
//import FileUploadPopover from './../../components/FileUpload/FileUploadPopover';
import { OverlayTrigger, Spinner, Popover } from 'react-bootstrap';
import { IoSend, IoAdd } from 'react-icons/io5';

const HomePage = () => {

  const [userInput, setUserInput] = useState('');

  const startMessageIntroduction = "Hallo! Ich bin dein digitaler Assistent und hier, um dir bei all deinen Anliegen rund um deine Haftpflicht- und Hausratversicherung zu helfen. " +
      "Mehr über mich erfährst du, wenn du auf folgenden Knopf klickst. \n"
  const startMessageQuestions = "Hast du eine Frage? Lege einfach los.";

  const [chatMessages, setChatMessages] = useState([]);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [insuranceProduct, setInsuranceProduct] = useState("axa-haushaft");
  const [count, setCount] = useState(sessionStorage.getItem("msgCount") ? parseInt(sessionStorage.getItem("msgCount")) : 0);

  const [addMessage, { isError, error }] = useAddMessageMutation();
  const [addMessageAnswer, { isErrorAnswer, errorAnswer }] = useAddMessageAnswerMutation();

  const navigate = useNavigate();

  /*const [queryParameters] = useSearchParams();*/

  useEffect(() => {
  }, );

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Check if the key pressed is Enter, without Shift, and the message is not just whitespace
    if (e.key === 'Enter' && !e.shiftKey && userInput.trim()) {
      e.preventDefault(); // Prevent the default action (form submission or newline)

      document.getElementById("button-send").click();
    }
  };

  const handleSendMessage = async(e) => {
    if (userInput.trim() !== '') {
      
      setCount(count + 1);
      sessionStorage.setItem("msgCount", sessionStorage.getItem("msgCount") ? parseInt(sessionStorage.getItem("msgCount")) + 1 : 1)

      if (sessionStorage.getItem("freemium") !== "true") {
        // Send the user's question to the server
        const serverResponse = await addMessage(userInput);

        // Clear the user input
        setUserInput('');

        // Check for error
        if (isError) {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { user: true, text: userInput },
            { user: false, text: "Es ist ein Fehler aufgetreten. Bitte versuche es später nochmals."}
          ]);
          console.log(error);
        } else {
        // Update chatMessages with user's question and server response
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { user: serverResponse.data.isUser, text: serverResponse.data.text }
          ]);
          setIsAnswerLoading(true);

          const serverResponseForAnswer = await addMessageAnswer({
            messageId: serverResponse.data.id,
            productName: insuranceProduct
          });

          if(isErrorAnswer) {
            setIsAnswerLoading(false);
            setChatMessages((prevMessages) => [
              ...prevMessages,
              { user: false, text: "Es ist ein Fehler aufgetreten. Bitte versuche es später nochmals."}
            ]);
            console.log(errorAnswer);
          } else {
            setIsAnswerLoading(false);
            serverResponseForAnswer.data.forEach((a) => {
              setChatMessages((prevMessages) => [
                ...prevMessages,
                { user: false, text: a.text }
              ]);
            })
          }
          
        }
      } else {
        // Clear the user input
        setUserInput('');

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { user: true, text: userInput }
        ]);
      }
      
    }
  };

  return (
    <div className='chat-container'>
      <div className='top-section'>
        Deine Versicherung für Haftplicht und Hausrat? <br />
        <ButtonGroup aria-label="Basic example">
          <Button variant="" active={ insuranceProduct === "axa-haushaft" } onClick={ () => { setInsuranceProduct("axa-haushaft") }} >AXA</Button>
          <Button variant="" active={ insuranceProduct === "zurich-haushaft" } onClick={ () => { setInsuranceProduct("zurich-haushaft") }}>Zürich</Button>
        </ButtonGroup>
      </div>
      <div className="main-section" >
        <div className='messages-container'>
          {/*
            queryParameters.get("from") === "payment" ? 
            <div>
              <div
                style={{ 
                  marginBottom: '10px',
                  maxWidth: '80%',
                  padding: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '20px',
                  color: "white",
                }}
                className={ "agent-msg"}>
                { "Du kannst den Chat weiterhin kostenlos nutzen. Die Registrierung war nur ein Test, ob du bereit bist, für den Service zu bezahlen. Bist du auch bereit, an einer Umfrage zur Nutzung von Paperdex teilzunehmen? Dies hilft uns in der Weiterentwicklung des Services. Sie dauert weniger als 5 Minuten." }
              </div>
              <Link to="https://forms.office.com/r/BKCYLydmtz" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-secondary" style={{ marginBottom: '10px', textDecoration: 'none' }} onKeyDown={(event) => {
                  if(event.key === "Enter") {
                    event.preventDefault();
                  }
                }}>
                  Zur Umfrage
                </Button>
              </Link>
            </div> :
            <></>
              */}
          {/*
            queryParameters.get("from") === "fileUpload" ? 
            <div>
              <div
                className={ "chat-message agent-msg"}>
                { "Dokumenten-Upload ist derzeit noch nicht möglich. Vielen Dank, dass du aber deine Bereitschaft zeigst, ein eigenes Dokument hochzuladen. Bist du auch bereit, an einer Umfrage zur Nutzung von Paperdex teilzunehmen? Dies hilft uns in der Weiterentwicklung des Services. Sie dauert weniger als 5 Minuten." }
              </div>
              <Link to="https://forms.office.com/r/BKCYLydmtz" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-secondary" style={{ marginBottom: '10px', textDecoration: 'none' }} onKeyDown={(event) => {
                  if(event.key === "Enter") {
                    event.preventDefault();
                  }
                }}>
                  Zur Umfrage
                </Button>
              </Link>
            </div> :
            <></>
              */}
          <div style={{ 
                marginBottom: '10px',
                maxWidth: '80%',
                padding: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                borderRadius: '20px',
                color: "white",
                whiteSpace: 'pre-line' 
              }}
              className={`chat-message agent-msg`}>
              {startMessageIntroduction} 
              <a href="https://synapptive.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                <Button variant="outline-light" style={{ 
                  marginBottom: '10px',
                  marginTop: '10px',
                  maxWidth: '80%',
                  padding: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '20px'
                }}>Mehr erfahren</Button>
              </a> <br />
              {startMessageQuestions}
            </div>
          {chatMessages && chatMessages.map((message, index) => (
            <div key={index} 
              style={{ 
                marginBottom: '10px',
                maxWidth: '80%',
                padding: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                borderRadius: '20px',
                color: "white",
                whiteSpace: 'pre-line' 
              }}
              className={`chat-message ${message.user ? 'my-msg' : 'agent-msg'}`}>
              {message.text}
            </div>
          ))}
          { 
            isAnswerLoading ? 
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Laden...</span>
            </Spinner> : 
            <></>
          }
          {
            count === 3 && !isAnswerLoading ?
            <div>
              <div
                style={{ 
                  marginBottom: '10px',
                  maxWidth: '80%',
                  padding: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '20px',
                  color: "white",
                }}
                className={ "agent-msg"}>
                { "Dein Feedback interessiert uns! Hilf uns, Paperdex zu verbessern, indem du diese kurze Umfrage ausfüllst. Es dauert weniger als eine Minute." } <br />
                <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAAf0RyiVUREk5MFJLWERBRU5TTU4xNlBYWlhNUktYSi4u" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                  <Button variant="outline-light" style={{ 
                    marginBottom: '10px',
                    marginTop: '10px',
                    maxWidth: '80%',
                    padding: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    borderRadius: '20px'
                  }}>zur Umfrage</Button>
                </a>
              </div>
            </div> :
             <></>
          }
          {/*
            count > 3 && sessionStorage.getItem("freemium") === "true" ?
            <div>
              <Button variant="outline-secondary" id="button-register" className='register-button' onKeyDown={(event) => {
              if(event.key === "Enter") {
                event.preventDefault();
              }
            }} onClick={() => {
              navigate("/register?from=payment");
            }}>
              Registrieren
            </Button>
            </div> :
             <></>
          */}
        </div>
        <div className='input-container'>
          <InputGroup className="mb-3 chat-input">
            <OverlayTrigger key="top" placement='top' trigger="click" overlay={
              // <FileUploadPopover />
              <Popover id="popover-basic">
                <Popover.Header as="h3">Dokument hochladen</Popover.Header>
                {
                  sessionStorage.getItem("freemium") === "true" ?
                  <Popover.Body>
                      Um ein Dokument hochzuladen, musst du dich registrieren. <br />
                      <Button 
                        variant="outline-secondary"
                        id="button-register"
                        className='register-button'
                        onClick={() => {
                          navigate("/register?from=fileUpload");
                        }}>
                          Registrieren
                      </Button>
                  </Popover.Body> :
                  <Popover.Body>
                      Das Hochladen von individuellen Dokumenten ist derzeit nicht implementiert.
                  </Popover.Body>
                }
              </Popover>
            }>
              <InputGroup.Text>
                <IoAdd />
              </InputGroup.Text>
            </OverlayTrigger>
            <Form.Control
              type="text"
              value={userInput}
              placeholder="Gib hier deine Frage ein"
              aria-label="Deine Frage"
              aria-describedby="basic-addon2"
              onChange={handleUserInput}
              onKeyDown={handleKeyDown}
            />
            <Button variant="outline-secondary" id="button-send" className='send-button' onClick={handleSendMessage}>
              <IoSend style={{transform: 'rotate(-45deg)'}} />
            </Button>
          </InputGroup>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
